import React, { useState, useContext } from 'react';
import { signIn, getCurrentUser } from '../../AuthService/AuthService';
import { AuthContext } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Browser } from '@capacitor/browser';

import GoogleLogo from '../../svg/GoogleLogo';
import AppleLogo from '../../svg/AppleLogo';
import LineLogo from '../../svg/LineLogo';
import KakaoLogo from '../../svg/KakaoLogo';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser, setSession } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await signIn(email, password);
      const currentUser = getCurrentUser();
      setUser(currentUser);
      setSession(result);
      navigate('/');
    } catch (error: any) {
      console.error('로그인 실패:', error);
      alert(error.message || JSON.stringify(error));
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      if (!googleClientId) {
        alert('Google Client ID가 정의되어 있지 않습니다.');
        return;
      }
      
      const redirectUri = window.location.origin + '/google-callback';
      const scope = 'openid profile email';
      const responseType = 'token';
      const state = Math.random().toString(36).substring(2);
  
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&response_type=${responseType}&scope=${encodeURIComponent(scope)}&state=${state}`;
  
      await Browser.open({ url: authUrl });
    } catch (error) {
      alert('Browser.open 호출 중 오류 발생: ' + error);
    }
  };

  const handleAppleLogin = async () => {
    console.log('ff')
    try {
      const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID;
      if (!appleClientId) {
        alert('Apple client ID가 정의되어 있지 않습니다.');
        return;
      }
      
      // API Gateway 엔드포인트를 리다이렉트 URI로 사용
      const redirectUri = 'https://3paions1nf.execute-api.ap-northeast-2.amazonaws.com/prod/apple-callback';
      const state = Math.random().toString(36).substring(2);
      
      // form_post 모드 사용 (이메일, 이름 요청 가능)
      const scope = 'email name';
      const responseMode = 'form_post';
      const responseType = 'code id_token';
      
      const authUrl = `https://appleid.apple.com/auth/authorize?response_type=${encodeURIComponent(
        responseType
      )}&client_id=${encodeURIComponent(
        appleClientId
      )}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&state=${encodeURIComponent(state)}&scope=${encodeURIComponent(
        scope
      )}&response_mode=${encodeURIComponent(responseMode)}`;
      
      await Browser.open({ url: authUrl });
    } catch (error) {
      alert('Apple 로그인 중 오류 발생: ' + error);
    }
  };

  const handleLineLogin = async () => {
    try {
      const lineClientId = process.env.REACT_APP_LINE_CLIENT_ID; 
      if (!lineClientId) {
        alert('Line client ID가 정의되어 있지 않습니다.');
        return;
      }
      const redirectUri = window.location.origin + '/line-callback';
      const scope = 'openid profile email';
      const responseType = 'code';
      const state = Math.random().toString(36).substring(2);

      const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=${responseType}&client_id=${lineClientId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&state=${state}&scope=${encodeURIComponent(scope)}`;
  
      await Browser.open({ url: authUrl });
    } catch (error) {
      alert('Line 로그인 중 오류 발생: ' + error);
    }
  };

  const handleKakaoLogin = async () => {
    try {
      const kakaoClientId = process.env.REACT_APP_KAKAO_CLIENT_ID;
      if (!kakaoClientId) {
        alert('Kakao client ID가 정의되어 있지 않습니다.');
        return;
      }
      const redirectUri = window.location.origin + '/kakao-callback';
      const responseType = 'code';
      const state = Math.random().toString(36).substring(2);
      const scope = 'account_email profile_nickname';
  
      const authUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoClientId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&response_type=${responseType}&state=${state}&scope=${encodeURIComponent(scope)}`;
  
      await Browser.open({ url: authUrl });
    } catch (error) {
      alert('Kakao 로그인 중 오류 발생: ' + error);
    }
  };

  return (
    <div style={styles.outerContainer}>
      <div style={styles.card}>
        <form onSubmit={handleSignIn} style={styles.form}>
          <h2 style={styles.title}>반가워요!</h2>
          <h3 style={styles.subtitle}>계정을 선택해주세요.</h3>

          <div style={styles.inputGroup}>
            <input
              type="email"
              placeholder="ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
              autoComplete="username"
            />
          </div>
          <div style={styles.inputGroup}>
            <input
              type="password"
              placeholder="PW"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
              autoComplete="current-password"
            />
          </div>

          <div style={styles.linksContainer}>
            <Link to="/find-account" style={styles.link}>
              비밀번호찾기/아이디찾기
            </Link>
            <Link to="/signup" style={styles.link}>
              회원가입
            </Link>
          </div>

          <button type="submit" style={styles.loginButton}>
            로그인
          </button>
        </form>

        <div style={styles.quickLoginContainer}>
          <h3 style={styles.quickLoginTitle}>3초만에 간편 가입</h3>

          <button onClick={handleGoogleLogin} style={styles.googleLoginButton}>
            <GoogleLogo style={{ width: '24px', height: '24px', marginRight: '8px' }} />
            구글로 시작하기
          </button>

          <button onClick={handleAppleLogin} style={styles.appleLoginButton}>
            <AppleLogo style={{ width: '24px', height: '24px', marginRight: '8px' }} />
            Apple로 시작하기
          </button>

          <div style={styles.dividerContainer}>
            <div style={styles.dividerLine} />
            <span style={styles.dividerText}>또는</span>
            <div style={styles.dividerLine} />
          </div>

          <div style={styles.iconButtonRow}>
            <button onClick={handleLineLogin} style={styles.lineIconButton}>
              <LineLogo style={{ width: '43px', height: '43px' }} />
            </button>
            <button onClick={handleKakaoLogin} style={styles.kakaoIconButton}>
              <KakaoLogo style={{ width: '43px', height: '43px' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '360px',
    backgroundColor: '#fff',
    borderRadius: '16px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    padding: '2rem 1.5rem',
  },
  form: {
    marginBottom: '2rem',
  },
  title: {
    margin: 0,
    marginBottom: '0.2rem',
    fontSize: '1.8rem',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 0,
    marginBottom: '1.5rem',
    fontSize: '1.1rem',
    textAlign: 'center',
    color: '#555',
  },
  inputGroup: {
    marginBottom: '1.2rem',
  },
  input: {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid #ccc',
    padding: '0.8rem 0',
    fontSize: '1rem',
    outline: 'none',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
    fontSize: '0.85rem',
  },
  link: {
    color: '#666',
    textDecoration: 'none',
  },
  loginButton: {
    width: '100%',
    padding: '0.9rem',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#D8A45F',
    color: '#000',
    fontSize: '1.1rem',
    cursor: 'pointer',
  },
  quickLoginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  quickLoginTitle: {
    margin: 0,
    marginBottom: '1rem',
    fontSize: '0.95rem',
    textAlign: 'center',
    color: '#333',
  },
  googleLoginButton: {
    width: '100%',
    padding: '0.9rem',
    marginBottom: '0.8rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#fff',
    color: '#333',
    fontSize: '1rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  appleLoginButton: {
    width: '100%',
    padding: '0.9rem',
    marginBottom: '0.8rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#fff',
    color: '#000',
    fontSize: '1rem',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
  },
  dividerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0',
  },
  dividerLine: {
    flex: 1,
    height: '1px',
    backgroundColor: '#ccc',
  },
  dividerText: {
    margin: '0 0.5rem',
    fontSize: '0.9rem',
    color: '#888',
  },
  iconButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '0.5rem',
  },
  lineIconButton: {
    flex: 1,
    padding: '0.8rem 0',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  kakaoIconButton: {
    flex: 1,
    padding: '0.8rem 0',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    color: '#3c1e1e',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  whatsAppIconButton: {
    flex: 1,
    padding: '0.8rem 0',
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default SignIn;
