// src/AuthService/cognitoAdmin.ts

/**
 * Lambda를 호출하여 지정한 이메일로 가입된 사용자가 존재하는지 확인합니다.
 *
 * Lambda 함수는 POST 요청으로 { email: string } 데이터를 받고,
 * JSON 응답으로 { exists: boolean }을 반환해야 합니다.
 */

export const checkUserExists = async (email: string): Promise<boolean> => {
  // Lambda API URL은 환경 변수로 설정합니다.
  const LAMBDA_EMAIL_SEARCH_URL = "https://bmw7t8m7o5.execute-api.ap-northeast-2.amazonaws.com/check-email";
  if (!LAMBDA_EMAIL_SEARCH_URL) {
    throw new Error('Lambda API URL for email search is not configured.');
  }
  try {
    console.log(email)
    const response = await fetch(LAMBDA_EMAIL_SEARCH_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }), // Lambda에 이메일 전달
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to check user existence');
    }

    const data = await response.json();
    // Lambda 응답 예시: { exists: true } 또는 { exists: false }
    return data.exists;
  } catch (error) {
    console.error('Error checking user existence via Lambda:', error);
    throw error;
  }
};

export const checkUserExistsBySub = async (sub: string): Promise<string | false> => {
  const LAMBDA_SUB_SEARCH_URL = "https://kysu8s5enj.execute-api.ap-northeast-2.amazonaws.com/check-line-sub";

  if (!LAMBDA_SUB_SEARCH_URL) {
    throw new Error('Lambda API URL for sub search is not configured.');
  }

  try {
    const response = await fetch(LAMBDA_SUB_SEARCH_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sub }), // Lambda에 sub 값을 전달합니다.
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to check user existence by sub');
    }

    const data = await response.json();
    // Lambda 응답 예시: { exists: true, email: "user@example.com" } 또는 { exists: false }
    if (data.exists) {
      return data.email; // 존재하면 이메일을 반환합니다.
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error checking user existence via Lambda (sub):', error);
    throw error;
  }
};
