import React from 'react';
import Layout from '../components/Layout';
import { Container, Box, Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const themes = [
  {
    id: '일상',
    displayName: '일상 회화',
  },
  {
    id: '비즈니스',
    displayName: '비즈니스 한국어',
  },
  {
    id: '여행',
    displayName: '여행 한국어',
  },
  {
    id: '문화',
    displayName: '문화/엔터테인먼트 한국어',
  },
  {
    id: '드라마',
    displayName: '드라마 한국어',
  }
];

const Plan: React.FC = () => {
  
  return (
    <Layout>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
          paddingTop: '10px',
        }}
      >
        {/* Fixed header section */}
        <Box 
          sx={{
            position: 'fixed',
            top: '10px',
            left: 0,
            width: '100%',
            background: 'white',
            zIndex: 10,
            paddingBottom: '16px',
          }}
        >
          <Container maxWidth="sm">
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ position: 'absolute', top: '0px', left: '16px', zIndex: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Plan</Typography>
              </Box>
              <Typography 
                variant="h5" 
                align="center" 
                gutterBottom 
                sx={{ 
                  fontWeight: 'bold',
                  mt: 3
                }}
              >
                K-learning
              </Typography>
            </Box>
          </Container>
        </Box>

        <Container
          maxWidth="sm"
          sx={{
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 8, // Increased padding top to accommodate fixed header
          }}
        >
          {/* Themes list */}
          <Stack spacing={3} width="92%" mb={4} mt={4}>
            {themes.map((theme) => (
              <Link 
                key={theme.id}
                to={`/plan/plan-detail?theme=${encodeURIComponent(theme.id)}`}
                style={{ textDecoration: 'none', display: 'block' }}
              >
                 <Box
                  sx={{
                    backgroundColor: '#F0AEAE',
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.5)',
                    p: 5,
                    mb: 1,
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="body1" color="textPrimary" fontWeight="bold" fontSize="1.2rem">
                    {theme.displayName}
                  </Typography>
                </Box>
              </Link>
            ))}
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default Plan;