import { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getUserAttributes } from '../AuthService/AuthService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { setProfileData } from '../store/profileSlice';

const Setting = () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state: RootState) => state.profile);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const attributes = await getUserAttributes();
        dispatch(
          setProfileData({
            name: attributes['custom:name'] || '',
            nickname: attributes['custom:nickname'] || '',
            introduction: attributes['custom:introduction'] || '',
            image: attributes['custom:picture'] || '/path/to/profile.jpg',
            dream1: attributes['custom:dream1'] || '',
            dream2: attributes['custom:dream2'] || '',
            dream3: attributes['custom:dream3'] || '',
            dream4: attributes['custom:dream4'] || '',
            dream5: attributes['custom:dream5'] || '',
            dream6: attributes['custom:dream6'] || '',
            dream7: attributes['custom:dream7'] || '',
            dreamtext: attributes['custom:dreamtext'] || '',
          })
        );
      } catch (error) {
        console.error('사용자 속성 가져오기 오류:', error);
        alert('사용자 정보를 불러오는 데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAttributes();
  }, [dispatch]);

  return (
    <Layout>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'linear-gradient(180deg, #FFF 0%, #D8A45F 97%)',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '10px',
          boxSizing: 'border-box',
        }}
      >
        {!isLoading ? (
          <Container
            maxWidth="sm"
            sx={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}
          >
            {/* 첫 번째 섹션 */}
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                paddingTop: '2vh',
                paddingBottom: '2vh',
                borderRadius: '8px', 
                boxShadow: 1, 
                marginBottom: '16px', 
              }}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/profile/edit">
                    <ListItemIcon>
                      <Avatar src={profileData.image} sx={{ width: 50, height: 50 }}  />
                    </ListItemIcon>
                    <ListItemText primary="프로필" secondary={profileData.nickname} />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
            <Divider sx={{ my: 2 }} />

            {/* 두 번째 섹션 */}
            {/* <Box
              sx={{
                backgroundColor: '#FFFFFF',
                paddingTop: '2vh',
                paddingBottom: '2vh',
                borderRadius: '8px',
                boxShadow: 1,
                marginBottom: '16px',
              }}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/profile/plan-setting">
                    <ListItemText primary="플랜설정" />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/payment-method">
                    <ListItemText primary="결제수단" />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
            <Divider sx={{ my: 2 }} /> */}

            {/* 세 번째 섹션 */}
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                paddingTop: '2vh',
                paddingBottom: '2vh',
                borderRadius: '8px',
                boxShadow: 1,
                marginBottom: '16px',
              }}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/profile/faq">
                    <ListItemText primary="자주묻는질문" />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/profile/customer-service">
                    <ListItemText primary="고객센터 문의" />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/profile/partnership">
                    <ListItemText primary="제휴 및 협업" />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
            <Divider sx={{ my: 2 }} />

            {/* 네 번째 섹션 */}
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                paddingTop: '2vh',
                paddingBottom: '2vh',
                borderRadius: '8px',
                boxShadow: 1,
                marginBottom: '16px',
              }}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/profile/app-setting">
                    <ListItemText primary="기능설정" />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/profile/app-version">
                    <ListItemText primary="앱버전 정보" />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Container>
        ) : null}
      </Box>
    </Layout>
  );
};

export default Setting;
