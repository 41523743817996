import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  Modal,
  Slide
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CreateIcon from '@mui/icons-material/Create';
import KeyboardIcon from '@mui/icons-material/Keyboard';

// 새로운 자료구조 사용
import { selectMessagesByParams } from '../../store/messagesSlice';

// 타입 정의
interface Message {
  text: string;
  textEng?: string;
  highlight: string;
  highlightEng?: string;
  background: string;
  textColor: string;
  audioUrl: string;
}

interface WritingProps {}

const Writing: React.FC<WritingProps> = () => {
  // URL 파라미터로 dayId ("1", "2", "3" 등)
  const params = useParams();
  const dayId = params.dayId || "1"; // 기본값 설정
  const location = useLocation();
  
  // URL 쿼리 파라미터 추출
  const queryParams = new URLSearchParams(location.search);
  
  // 쿼리 파라미터로부터 theme, category, difficulty를 가져오거나 기본값 설정
  const themeParam = queryParams.get('theme') || '일상';
  const categoryParam = queryParams.get('category') || '친구';
  const difficultyParam = queryParams.get('difficulty') || '초급';

  const dayKey = `Day${dayId}`;
  
  // 새로운 데이터 구조에 맞게 selectMessagesByParams 호출
  const messages = useSelector(selectMessagesByParams(
    themeParam, 
    categoryParam, 
    difficultyParam, 
    dayKey
  )) as Message[];

  // 하이라이트가 있는 유효한 메시지만 필터링
  const validMessages = useMemo(() => {
    return messages.filter(msg => msg.highlight);
  }, [messages]);
  
  // 현재 메시지 인덱스 관리 (유효한 메시지 배열 기준)
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  
  // 다음 유효한 메시지 인덱스를 찾는 함수
  const findNextValidIndex = (currentValidIndex: number): number => {
    const nextValidIndex = currentValidIndex + 1;
    return nextValidIndex < validMessages.length ? nextValidIndex : -1;
  };
  
  // 현재 메시지 (유효한 메시지 배열 기준)
  const currentMessage = validMessages[currentIndex] || {} as Message;
  
  // 다음 메시지로 이동하는 함수 (유효한 메시지 배열 기준)
  const handleNextMessage = (): void => {
    const nextValidIndex = findNextValidIndex(currentIndex);
    
    if (nextValidIndex !== -1) {
      setCurrentIndex(nextValidIndex);
      // 타이핑 모드를 초기화
      setMode(null);
      setTypedText('');
      // 캔버스 초기화
      clearCanvas();
    }
  };

  // 다음 하이라이트 문장이 있는지 확인 (유효한 메시지 배열 기준)
  const hasNextMessage = (): boolean => {
    return findNextValidIndex(currentIndex) !== -1;
  };

  // 오디오 재생 관련
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const handlePlay = (url: string) => {
    if (!url || isPlaying) return;
    const audio = new Audio(url);
    audioRef.current = audio;
    setIsPlaying(true);
    audio
      .play()
      .catch((error) => {
        console.error('Audio play error:', error);
        setIsPlaying(false);
      });
    audio.addEventListener('ended', () => {
      setIsPlaying(false);
    });
  };

  // 모드: 손글씨(drawing) vs 타자(typing)
  const [mode, setMode] = useState<'drawing' | 'typing' | null>(null);

  // 손글씨 모드 관련
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [lastX, setLastX] = useState<number | null>(null);
  const [lastY, setLastY] = useState<number | null>(null);

  // 캔버스 초기화 함수
  const initCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    // 캔버스 초기화
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    // 현재 highlight 텍스트를 회색으로 그리기
    if (currentMessage.highlight) {
      // 글자 하나씩 분리하고 공백 제거
      const characters = currentMessage.highlight.split('').filter(char => char !== ' ');
      
      // 글꼴 설정 - 크기 키움
      const fontSize = 60;
      ctx.font = `${fontSize}px Arial`;
      ctx.textAlign = 'center';
      ctx.fillStyle = '#DDDDDD'; // 연한 회색
      ctx.textBaseline = 'middle';
      
      // 그리드 셀 크기 계산
      const cellWidth = canvas.width / 2;
      const cellHeight = fontSize * 2; // 충분한 여백 확보
      
      // 시작 위치 (상단 여백)
      const startY = canvas.height * 0.2;
      
      // 2열로 그리드 형태 배치
      characters.forEach((char, index) => {
        // 열과 행 계산
        const column = index % 2;
        const row = Math.floor(index / 2);
        
        // 문자 위치 계산
        const x = column * cellWidth + cellWidth / 2;
        const y = startY + row * cellHeight + cellHeight / 2;
        
        // 그리드 셀 그리기
        ctx.strokeStyle = '#EEEEEE'; // 연한 회색 테두리
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.rect(column * cellWidth, startY + row * cellHeight, cellWidth, cellHeight);
        ctx.stroke();
        
        // 문자 그리기
        ctx.fillText(char, x, y);
      });
    }
  }, [currentMessage.highlight]);

  // clearCanvas 함수: 캔버스 내용을 지움
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // 회색 가이드 텍스트 다시 그리기
    initCanvas();
  };

  useEffect(() => {
    if (mode === 'drawing') {
      const timeout = setTimeout(() => {
        initCanvas();
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [mode, initCanvas]);

  const handleCanvasMouseDown = (
    e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>
  ) => {
    setIsDrawing(true);
    const { clientX, clientY } =
      'touches' in e ? e.touches[0] : (e as React.MouseEvent<HTMLCanvasElement>);
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect) {
      setLastX(clientX - rect.left);
      setLastY(clientY - rect.top);
    }
  };

  const handleCanvasMouseMove = (
    e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>
  ) => {
    if (!isDrawing) return;
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;
    const { clientX, clientY } =
      'touches' in e ? e.touches[0] : (e as React.MouseEvent<HTMLCanvasElement>);
    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect) return;
    const x = clientX - rect.left;
    const y = clientY - rect.top;

    ctx.strokeStyle = 'black';
    ctx.lineWidth = 6;
    ctx.lineCap = 'round';

    if (lastX !== null && lastY !== null) {
      ctx.beginPath();
      ctx.moveTo(lastX, lastY);
      ctx.lineTo(x, y);
      ctx.stroke();
      ctx.closePath();
    }
    setLastX(x);
    setLastY(y);
  };

  const handleCanvasMouseUp = () => {
    setIsDrawing(false);
    setLastX(null);
    setLastY(null);
  };

  // 타자 모드 관련
  const [typedText, setTypedText] = useState<string>('');
  
  // 타자 입력 변경 핸들러
  const handleChangeTypedText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTypedText(e.target.value);
  };
  
  // 엔터 키 입력 핸들러
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      // 다음 액션 수행 (타이핑 내용 확인 후 다음으로 진행)
      handleTypingComplete();
    }
  };
  
  // 타이핑 완료 처리
  const handleTypingComplete = () => {
    // 여기에 입력된 텍스트 검증 로직을 추가할 수 있습니다
    // 예: 정확도 확인, 오류 표시 등
    
    // 텍스트 입력 초기화
    setTypedText('');
    
    // 모달 닫기
    setMode(null);
    
    // 다음 메시지로 이동하거나 완료
    if (hasNextMessage()) {
      // 잠시 후 다음 메시지로 이동
      setTimeout(() => {
        handleNextMessage();
      }, 500);
    } else {
      // 모든 메시지를 완료한 경우 계속 진행
      // 마지막 메시지일 경우 자동으로 다음 단계로 이동할 수도 있습니다
    }
  };

  const backPage = () => {
    window.history.back();
  };

  return (
    <Box>
      {/* 페이지 배경 및 레이아웃 */}
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
          paddingTop: '10px',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            height: '90vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 8,
            pb: 8,
          }}
        >
          {/* 상단 바: 뒤로가기, DAY, 알림 아이콘 */}
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              right: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box onClick={backPage} sx={{ cursor: 'pointer', color: 'black' }}>
              <ArrowBackIosNewIcon sx={{ fontSize: 24 }} />
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              DAY {dayId}
            </Typography>
            <NotificationsNoneIcon sx={{ fontSize: 24 }} />
          </Box>

          {/* 가운데 카드: 학습 문장 */}
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              borderRadius: '24px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              width: '92%',
              maxWidth: 400,
              pt: '60px',
              pb:'60px',
              pr: '20px',
              pl: '20px',
              textAlign: 'center',
              position: 'relative',
              mt: 5,
              mb: 9,
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: '30px' }}>
              "{currentMessage.highlight || ''}"를 써보세요.
              <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                {currentIndex + 1}/{validMessages.length}
              </Typography>
            </Typography>
            <Box sx={{ mb: '30px' }}>
              <VolumeUpIcon
                onClick={() => currentMessage.audioUrl ? handlePlay(currentMessage.audioUrl) : undefined}
                sx={{ fontSize: 40, color: '#666', cursor: 'pointer' }}
              />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: '30px' }}>
              "{currentMessage.highlight || ''}"
            </Typography>
            <Typography variant="body1" sx={{ color: '#666' }}>
              = {currentMessage.highlightEng || ''}
            </Typography>
          </Box>

          {/* 모드 선택 버튼들 */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              onClick={() => setMode('drawing')}
              color={mode === 'drawing' ? 'primary' : 'default'}
            >
              <CreateIcon sx={{ fontSize: 100 }} />
            </IconButton>
            <IconButton
              onClick={() => setMode('typing')}
              color={mode === 'typing' ? 'primary' : 'default'}
            >
              <KeyboardIcon sx={{ fontSize: 100 }} />
            </IconButton>
          </Box>

          {/* 하단 NEXT 버튼 */}
          <Box
            sx={{
              position: 'absolute',
              bottom: '0px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 10000,
            }}
          >
            {hasNextMessage() ? (
              // 다음 메시지가 있으면 다음 메시지로 이동
              <Button
                variant="contained"
                onClick={handleNextMessage}
                sx={{
                  backgroundColor: '#9D76DD',
                  borderRadius: '40px',
                  padding: '10px 20px',
                  color: '#FFF',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  '&:hover': {
                    backgroundColor: '#B28FF0',
                  },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: 16, mr: 1 }} />
                NEXT
              </Button>
            ) : (
              // 다음 메시지가 없으면 end 페이지로 이동
              <Link 
                to={`/plan/end/${dayId}?theme=${encodeURIComponent(
                  themeParam
                )}&category=${encodeURIComponent(
                  categoryParam
                )}&difficulty=${encodeURIComponent(difficultyParam)}`} 
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#9D76DD',
                    borderRadius: '40px',
                    padding: '10px 20px',
                    color: '#FFF',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    '&:hover': {
                      backgroundColor: '#B28FF0',
                    },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: 16, mr: 1 }} />
                  NEXT
                </Button>
              </Link>
            )}
          </Box>
        </Container>
      </Box>

      {/* 모달 (손글씨/타자) - Slide 애니메이션 적용 */}
      <Modal
        open={mode !== null}
        onClose={() => setMode(null)}
        BackdropProps={{
          style: { backgroundColor: 'transparent' },
        }}
      >
        <Slide direction="up" in={mode !== null} mountOnEnter unmountOnExit>
          <Box
            sx={{
              width: '100%',
              height: '87.5%',
              position: 'absolute',
              top: '12.5%',
              left: 0,
              bgcolor: mode === 'typing' ? 'transparent' : '#FFF',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '48px 48px 0 0',
              borderTop: mode === 'typing' ? 'none' : '1px solid #DDD',
            }}
          >
            {/* 손글씨 모드 */}
            {mode === 'drawing' && (
              <Box
                sx={{
                  flex: 1,
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <canvas
                  ref={canvasRef}
                  width={400}
                  height={800} // 필요에 따라 조정
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#fff',
                    cursor: 'crosshair',
                    borderRadius: '48px 48px 0 0',
                  }}
                  onMouseDown={handleCanvasMouseDown}
                  onMouseMove={handleCanvasMouseMove}
                  onMouseUp={handleCanvasMouseUp}
                  onMouseLeave={handleCanvasMouseUp}
                  onTouchStart={handleCanvasMouseDown}
                  onTouchMove={handleCanvasMouseMove}
                  onTouchEnd={handleCanvasMouseUp}
                />
                {/* 지우기 버튼 */}
                <Button
                  variant="outlined"
                  onClick={clearCanvas}
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '20px',
                  }}
                >
                  지우기
                </Button>
              </Box>
            )}

            {/* 타자 모드 */}
            {mode === 'typing' && (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#FFF',
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '24px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    p: 4,
                    maxWidth: 350,
                    width: '80%',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="subtitle1" sx={{ mb: 3 }}>
                    "{currentMessage.highlight || ''}"를 써보세요.
                    <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                      {currentIndex + 1}/{validMessages.length}
                    </Typography>
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={typedText}
                    onChange={handleChangeTypedText}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    placeholder="여기에 입력하고 엔터를 누르세요..."
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '30px',
                      },
                    }}
                  />
                  
                  {/* 엔터 대신 버튼으로도 제출할 수 있게 함 */}
                  <Button
                    variant="contained"
                    onClick={handleTypingComplete}
                    sx={{
                      mt: 2,
                      backgroundColor: '#9D76DD',
                      borderRadius: '30px',
                      '&:hover': {
                        backgroundColor: '#B28FF0',
                      },
                    }}
                  >
                    확인
                  </Button>
                </Box>
              </Box>
            )}

          </Box>
        </Slide>
      </Modal>
    </Box>
  );
};

export default Writing;