import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import { Container, Box, Typography, Grid, Button, Modal } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { getUserAttributes, updateUserAttributes } from '../../AuthService/AuthService';
import { selectCategoriesByTheme } from '../../store/messagesSlice';

// 예시용 SVG 컴포넌트 (실제로는 프로젝트 내 SVG파일 import해서 사용)
const SvgPlan: React.FC = () => (
  <svg width="60" height="60" viewBox="0 0 60 60">
    <rect x="5" y="5" width="50" height="50" fill="#ccc" />
  </svg>
);

// 테마에 따른 카테고리 이름 표시 설정
const getCategoryDisplayName = (theme: string, categoryId: string): string => {
  const themeCategories: Record<string, Record<string, string>> = {
    '일상': {
      '친구': '일상(친구)',
      '썸': '일상(썸)'
    },
    '비즈니스': {
      '출근': '비즈니스(출근)',
      '미팅': '비즈니스(미팅)'
    },
    '여행': {
      '쇼핑': '여행(쇼핑)',
      '관광': '여행(관광)'
    },
    '문화': {
      '콘서트': '문화(콘서트)',
      '영화관': '문화(영화관)'
    },
    '드라마': {
      '로맨스': '드라마(로맨스)',
      '스릴러': '드라마(스릴러)'
    }
  };

  return themeCategories[theme]?.[categoryId] || categoryId;
};

const PlanDetail: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const themeParam = searchParams.get('theme') || '일상';
  
  // Redux에서 테마에 해당하는 카테고리 가져오기
  const categoriesSelector = useMemo(
    () => selectCategoriesByTheme(themeParam),
    [themeParam]
  );
  
  const categories = useSelector(categoriesSelector);

  // 모달 열림 여부
  const [openPlanModal, setOpenPlanModal] = useState(false);

  // 모달에서 보여줄 현재 plan1 / plan2 값
  const [plan1Val, setPlan1Val] = useState('');
  const [plan2Val, setPlan2Val] = useState('');

  // 플랜 카드 클릭 시 선택된 플랜 (1: 왼쪽, 2: 오른쪽)
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null);

  // 선택된 카테고리
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  // 플랜 저장 형식 - "테마|카테고리" 형태로 저장
  const formatPlanValue = (theme: string, category: string): string => {
    return `${theme}|${category}`;
  };

  // 난이도 선택 페이지로 이동하는 함수
  const navigateToDifficulty = (category: string) => {
    navigate(`/plan/day?theme=${encodeURIComponent(themeParam)}&category=${encodeURIComponent(category)}`);
  };

  // 카테고리 박스 클릭 시 실행되는 함수
  const handleCategoryClick = async (category: string) => {
    setSelectedCategory(category);
    
    try {
      const attributes = await getUserAttributes();
      const plan1 = attributes['custom:plan1'] || '';
      const plan2 = attributes['custom:plan2'] || '';
      
      // 아무 플랜도 없으면 plan1에 저장하고 이동
      if (!plan1 && !plan2) {
        const planValue = formatPlanValue(themeParam, category);
        await updateUserAttributes({ 'custom:plan1': planValue });
        navigateToDifficulty(category);
      }
      // plan1만 있고 plan2는 비어있을 경우
      else if (plan1 && !plan2) {
        // 이미 plan1에 같은 테마/카테고리가 있는지 확인
        const existingPlan = plan1.split('|');
        if (existingPlan[0] === themeParam && existingPlan[1] === category) {
          // 이미 같은 플랜이 저장되어 있으면 바로 이동
          navigateToDifficulty(category);
        } else {
          // 다른 플랜이면 plan2에 저장하고 이동
          const planValue = formatPlanValue(themeParam, category);
          await updateUserAttributes({ 'custom:plan2': planValue });
          navigateToDifficulty(category);
        }
      }
      // 두 플랜 모두 값이 있는 경우
      else if (plan1 && plan2) {
        // 이미 저장된 값 중 하나라도 같은 경우
        const plan1Parts = plan1.split('|');
        const plan2Parts = plan2.split('|');
        
        if ((plan1Parts[0] === themeParam && plan1Parts[1] === category) || 
            (plan2Parts[0] === themeParam && plan2Parts[1] === category)) {
          // 이미 같은 플랜이 저장되어 있으면 바로 이동
          navigateToDifficulty(category);
        } else {
          // 두 플랜이 모두 다른 경우 -> 모달 열고 plan1Val, plan2Val 세팅
          setPlan1Val(plan1);
          setPlan2Val(plan2);
          setOpenPlanModal(true);
        }
      }
    } catch (error) {
      console.error('플랜 정보 조회 오류:', error);
    }
  };

  // 모달에서 반납 버튼 클릭 시: 선택된 플랜을 비움
  const handleReturnPlan = async () => {
    try {
      if (selectedPlan === 1) {
        await updateUserAttributes({ 'custom:plan1': '' });
        setPlan1Val('');
      } else if (selectedPlan === 2) {
        await updateUserAttributes({ 'custom:plan2': '' });
        setPlan2Val('');
      }
      
      setOpenPlanModal(false);
      setSelectedPlan(null);
      
      // 반납 후 새 플랜 저장하고 난이도 선택 페이지로 이동
      if (selectedCategory) {
        const planValue = formatPlanValue(themeParam, selectedCategory);
        // 비워진 plan 슬롯에 새 플랜 저장
        if (selectedPlan === 1) {
          await updateUserAttributes({ 'custom:plan1': planValue });
        } else if (selectedPlan === 2) {
          await updateUserAttributes({ 'custom:plan2': planValue });
        }
        navigateToDifficulty(selectedCategory);
      }
    } catch (error) {
      console.error('플랜 반납 오류:', error);
    }
  };

  // 테마 이름에 따른 표시 이름 가져오기
  const getThemeDisplayName = (theme: string): string => {
    const themeNames: Record<string, string> = {
      '일상': '일상 회화',
      '비즈니스': '비즈니스 한국어',
      '여행': '여행 한국어',
      '문화': '문화/엔터테인먼트 한국어',
      '드라마': '드라마 한국어'
    };
    
    return themeNames[theme] || theme;
  };

  // 플랜 표시 이름 가져오기
  const getPlanDisplayName = (planValue: string): string => {
    if (!planValue) return '플랜이 비어있습니다';
    
    const [theme, category] = planValue.split('|');
    if (!theme || !category) return planValue; // 형식이 맞지 않으면 원래 값 반환
    
    return getCategoryDisplayName(theme, category);
  };

  // 카테고리 표시 항목 미리 계산
  const categoryDisplayItems = useMemo(() => {
    return categories.map((category) => ({
      id: category,
      displayName: getCategoryDisplayName(themeParam, category)
    }));
  }, [categories, themeParam]);

  return (
    <Layout>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
          paddingTop: '10px',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 8,
          }}
        >
          {/* 상단 영역: 뒤로가기 아이콘, 제목, 알림 아이콘 */}
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              right: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <ArrowBackIosNewIcon
              sx={{ fontSize: 24, cursor: 'pointer' }}
              onClick={() => navigate('/plan')}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {getThemeDisplayName(themeParam)}
            </Typography>
            <NotificationsNoneIcon sx={{ fontSize: 24 }} />
          </Box>

          <Grid container spacing={2.5} justifyContent="center" sx={{ pt: 2, width:'96%' }}>
            {categoryDisplayItems.map((item) => (
              <Grid item xs={6} key={item.id} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  onClick={() => handleCategoryClick(item.id)}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '25px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.5)',
                    width: '100%',
                    aspectRatio: '1',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    fontWeight="bold"
                    sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                  >
                    {item.displayName}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* 모달: 상단 영역과 하단 영역을 하나의 박스로 합침 */}
      <Modal open={openPlanModal} onClose={() => setOpenPlanModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '85%',
            maxWidth: 400,
            borderRadius: 3,
            boxShadow: 24,
            overflow: 'hidden',
            bgcolor: '#E0C9FD',
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {/* 텍스트 영역 */}
          <Box>
            {/* <Typography variant="body1" sx={{ mb: 1 }}>
              당신의 현재 플랜은 <strong>Standard plan</strong>입니다.
            </Typography> */}
            <Typography variant="body1">
              현재 진행중인 플랜 중 하나를 반납해주세요.
            </Typography>
          </Box>

          {/* 플랜 업그레이드 버튼 영역 (오른쪽 정렬) */}
          {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#CEA7FE',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#CEA7FE',
                  opacity: 0.9,
                },
              }}
            >
              플랜 업그레이드
            </Button>
          </Box> */}

          {/* 플랜 카드 영역 */}
          <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
            {/* 왼쪽 카드 (plan1) */}
            <Box
              onClick={() => setSelectedPlan(1)}
              sx={{
                backgroundColor: selectedPlan === 1 ? '#FFF9C4' : '#fff',
                color: '#000',
                textAlign: 'center',
                p: 2,
                borderRadius: 2,
                cursor: 'pointer',
                width: '100px',
              }}
            >
              <SvgPlan />
              <Typography variant="body2" sx={{ mt: 1, wordBreak: 'break-all' }}>
                {getPlanDisplayName(plan1Val)}
              </Typography>
            </Box>

            {/* 오른쪽 카드 (plan2) */}
            <Box
              onClick={() => setSelectedPlan(2)}
              sx={{
                backgroundColor: selectedPlan === 2 ? '#FFF9C4' : '#fff',
                color: '#000',
                textAlign: 'center',
                p: 2,
                borderRadius: 2,
                cursor: 'pointer',
                width: '100px',
              }}
            >
              <SvgPlan />
              <Typography variant="body2" sx={{ mt: 1, wordBreak: 'break-all' }}>
                {getPlanDisplayName(plan2Val)}
              </Typography>
            </Box>
          </Box>

          {/* 하단 버튼 영역 */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              sx={{
                flex: 1,
                mr: 1,
                borderColor: '#000',
                color: '#000',
                backgroundColor: '#fff',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
              onClick={() => {
                setOpenPlanModal(false);
                setSelectedPlan(null);
              }}
            >
              취소
            </Button>
            <Button
              variant="outlined"
              sx={{
                flex: 1,
                ml: 1,
                borderColor: '#000',
                color: '#000',
                backgroundColor: '#fff',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
              onClick={handleReturnPlan}
              disabled={selectedPlan === null}
            >
              반납
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default PlanDetail;