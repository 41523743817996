import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import type { Params } from 'react-router-dom';
import Layout from '../../components/Layout';
import {
  Container,
  Box,
  Typography,
  keyframes, 
  Button,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getUserAttributes, updateUserAttributes } from '../../AuthService/AuthService';

import PlanShadow from '../../svg/PlanShadow';
import PlanCharacter from '../../svg/PlanCharacter';

// 캐릭터를 위아래로 둥실둥실 움직이는 애니메이션
const floatAnimation = keyframes`
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
`;

// 그림자가 캐릭터 움직임에 따라 작아졌다 커졌다 하는 애니메이션
const shadowAnimation = keyframes`
  0%, 100% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
`;

const backPage = () => {
  window.history.back();
}

interface PlanEndParams extends Params {
  dayId: string;
}

const PlanEnd: React.FC = () => {
  const { dayId } = useParams<PlanEndParams>();
  const location = useLocation();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  console.log(isLoading)
  const [error, setError] = useState<string | null>(null);
  const isUpdatingRef = useRef(false);
  
  // URL 파라미터에서 테마와 카테고리 정보 가져오기
  const searchParams = new URLSearchParams(location.search);
  const themeParam = searchParams.get('theme') || '일상';
  const categoryParam = searchParams.get('category') || '';

  useEffect(() => {
    if (!dayId) return;
    if (isUpdatingRef.current || isUpdated) return;
    
    const updatePlanDays = async () => {
      isUpdatingRef.current = true;
      setIsLoading(true);
      setError(null);
      
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        const attributes = await getUserAttributes();
        const plan1 = attributes['custom:plan1'] || '';
        const plan2 = attributes['custom:plan2'] || '';
        
        let updatedPlan1 = plan1;
        let updatedPlan2 = plan2;
        let updated = false;
        
        if (plan1) {
          const plan1Parts = plan1.split('|');
          const theme = plan1Parts[0];
          const category = plan1Parts[1];
          
          if (theme === themeParam && category === categoryParam) {
            let completedDays = plan1Parts.length > 2 ? plan1Parts[2].split(',') : [];
            if (!completedDays.includes(dayId)) {
              completedDays.push(dayId);
              completedDays.sort((a, b) => Number(a) - Number(b));
              updatedPlan1 = `${theme}|${category}|${completedDays.join(',')}`;
              
              try {
                await updateUserAttributes({ 'custom:plan1': updatedPlan1 });
                updated = true;
              } catch (updateError: unknown) {
                const errorMessage = typeof updateError === 'object' && updateError !== null 
                  ? String(updateError) 
                  : 'Unknown error';
                
                if (errorMessage.includes('TooManyRequests')) {
                  await new Promise(resolve => setTimeout(resolve, 2000));
                  await updateUserAttributes({ 'custom:plan1': updatedPlan1 });
                  updated = true;
                } else {
                  throw updateError;
                }
              }
            } else {
              updated = true;
            }
          }
        }
        
        if (!updated && plan2) {
          const plan2Parts = plan2.split('|');
          const theme = plan2Parts[0];
          const category = plan2Parts[1];
          
          if (theme === themeParam && category === categoryParam) {
            let completedDays = plan2Parts.length > 2 ? plan2Parts[2].split(',') : [];
            if (!completedDays.includes(dayId)) {
              completedDays.push(dayId);
              completedDays.sort((a, b) => Number(a) - Number(b));
              updatedPlan2 = `${theme}|${category}|${completedDays.join(',')}`;
              
              try {
                await updateUserAttributes({ 'custom:plan2': updatedPlan2 });
                updated = true;
              } catch (updateError: unknown) {
                const errorMessage = typeof updateError === 'object' && updateError !== null 
                  ? String(updateError) 
                  : 'Unknown error';
                
                if (errorMessage.includes('TooManyRequests')) {
                  await new Promise(resolve => setTimeout(resolve, 2000));
                  await updateUserAttributes({ 'custom:plan2': updatedPlan2 });
                  updated = true;
                } else {
                  throw updateError;
                }
              }
            } else {
              updated = true;
            }
          }
        }
        
        setIsUpdated(true);
      } catch (error: unknown) {
        console.error('플랜 정보 업데이트 오류:', error);
        setError('플랜 정보 업데이트 중 오류가 발생했습니다. 나중에 다시 시도해주세요.');
      } finally {
        setIsLoading(false);
        isUpdatingRef.current = false;
      }
    };
    
    updatePlanDays();
  }, [dayId, themeParam, categoryParam, isUpdated]);

  return (
    <Layout>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
          paddingTop: '10px',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 8,
            pb: 8,
          }}
        >
          {/* 상단 헤더: 뒤로가기, TITLE, 알림 */}
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              right: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box onClick={backPage} sx={{ cursor: 'pointer', color: 'black' }}>
              <ArrowBackIosNewIcon sx={{ fontSize: 24 }} />
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {`DAY ${dayId}`}
            </Typography>
            <NotificationsNoneIcon sx={{ fontSize: 24 }} />
          </Box>

          {/* 말풍선 + 캐릭터 + 그림자 UI 영역 */}
          <Box
            sx={{
              mt: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* 말풍선 */}
            <Box
              sx={{
                position: 'relative',
                backgroundColor: '#fff',
                color: '#333',
                borderRadius: '30px',
                padding: '30px',
                maxWidth: '100%',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  // 그림자 추가
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: 'pre-line',
                  fontSize: '20px',
                  textAlign: 'center',
                }}
              >
                {`Day ${dayId} 플랜을 완료했어!\n와, 이걸 끝까지 다 하다니!\n완전 인정이야.\n이렇게 한국어 마스터 해보자구!`}
              </Typography>
              {/* 말풍선 꼬리 (아래쪽) */}
              <Box
                sx={{
                  content: '""',
                  position: 'absolute',
                  bottom: '-16px',
                  left: '150px',
                  width: '0',
                  height: '0',
                  borderLeft: '16px solid transparent',
                  borderRight: '16px solid transparent',
                  borderTop: '16px solid #fff',
                  filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))',  // 그림자 추가
                }}
              />
            </Box>

            {/* 캐릭터 SVG (둥실둥실 애니메이션) */}
            <Box
              sx={{
                mt: 8,
                animation: `${floatAnimation} 3s ease-in-out infinite`,
              }}
            >
              <PlanCharacter />
            </Box>

            {/* 그림자 SVG (캐릭터 움직임에 따라 크기 변화) */}
            <Box
              sx={{
                mt: 1,
                animation: `${shadowAnimation} 3s ease-in-out infinite`,
                transformOrigin: 'center center',
              }}
            >
              <PlanShadow />
            </Box>
            
            {/* 에러 메시지 표시 */}
            {error && (
              <Box
                sx={{
                  backgroundColor: 'rgba(255, 0, 0, 0.1)',
                  color: 'red',
                  padding: '10px',
                  borderRadius: '5px',
                  marginTop: '10px',
                  textAlign: 'center',
                }}
              >
                <Typography variant="body2">{error}</Typography>
              </Box>
            )}
            
            <Box
              sx={{
                position: 'absolute',
                bottom: '40px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <Link to={`/plan`} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#9D76DD',
                    borderRadius: '40px',
                    padding: '10px 20px',
                    color: '#FFF',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    '&:hover': {
                      backgroundColor: '#B28FF0',
                    },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: 16, mr: 1 }} />
                  NEXT
                </Button>
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export default PlanEnd;
