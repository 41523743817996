import React, { useState } from 'react';
import Layout from '../../components/Layout';
import { Container, Box, Typography, Grid} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SmallCharacter from '../../svg/SmallCharacter';
import PlanCharacter from '../../svg/PlanCharacter';
import PlanShadow from '../../svg/PlanShadow';
import { keyframes } from '@mui/system';

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

const shadowAnimation = keyframes`
  0% { transform: scale(1.2); }
  50% { transform: scale(0.8); }
  100% { transform: scale(1.2); }
`;

const days = [ 'DAY 1', 'DAY 2', 'DAY 3', 'DAY 4', 'DAY 5', 'DAY 6', 'DAY 7' ];

// 테마에 따른 카테고리 이름 표시 설정
const getCategoryDisplayName = (theme: string, categoryId: string): string => {
  const themeCategories: Record<string, Record<string, string>> = {
    '일상': {
      '친구': '일상(친구)',
      '썸': '일상(썸)'
    },
    '비즈니스': {
      '출근': '비즈니스(출근)',
      '미팅': '비즈니스(미팅)'
    },
    '여행': {
      '쇼핑': '여행(쇼핑)',
      '관광': '여행(관광)'
    },
    '문화': {
      '콘서트': '문화(콘서트)',
      '영화관': '문화(영화관)'
    },
    '드라마': {
      '로맨스': '드라마(로맨스)',
      '스릴러': '드라마(스릴러)'
    }
  };

  return themeCategories[theme]?.[categoryId] || categoryId;
};

const Day: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  
  // URL 파라미터 추출
  const themeParam = searchParams.get('theme') || '일상';
  const categoryParam = searchParams.get('category') || '친구';
  const difficultyParam = searchParams.get('difficulty') || '초급';
  
  // 선택된 카테고리 표시 이름
  const categoryDisplayName = getCategoryDisplayName(themeParam, categoryParam);

  // 전환 화면 관련 상태
  const [transitionActive, setTransitionActive] = useState(false);

  // DAY 버튼 클릭 시, 전환 화면을 띄운 후 3초 후 targetUrl로 이동
  const handleDayClick = (day: string) => {
    const dayNumber = day.split(' ')[1]; // "DAY 2" -> "2"
    const url = `/plan/listening/${dayNumber}?theme=${encodeURIComponent(themeParam)}&category=${encodeURIComponent(categoryParam)}&difficulty=${encodeURIComponent(difficultyParam)}`;
    setTransitionActive(true);
    setTimeout(() => {
      navigate(url);
    }, 3000);
  };

  // 전환 화면 렌더링
  if (transitionActive) {
    return (
      <Layout>
        <Box
          sx={{
            width: '100%',
            minHeight: '100vh',
            background: 'white',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            position: 'relative',
            paddingTop: '10px',
          }}
        >
          {/* 고정된 네비게이션 헤더 */}
          <Box
            sx={{
              position: 'fixed',
              top: '10px',
              left: 0,
              right: 0,
              padding: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              zIndex: 1000,
            }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: 24 }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {categoryDisplayName}
            </Typography>
            <NotificationsNoneIcon sx={{ fontSize: 24 }} />
          </Box>

          <Container
            maxWidth="sm"
            sx={{
              height: 'calc(100vh - 100px)',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: 12, // Increased to accommodate fixed header
            }}
          >
            {/* 전환 화면 콘텐츠 */}
            <Box
              sx={{
                mt: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
              }}
            >
              {/* "LET'S GET IT !" 텍스트 */}
              <Typography
                variant="h3"
                sx={{
                  color: '#A24ABB',    // 필요에 따라 보라색 계열 색상 조정
                  fontWeight: 'bold',
                  mb: 8,
                  textAlign: 'center',
                }}
              >
                LET'S GET IT !
              </Typography>

              {/* 캐릭터 SVG (둥실둥실 애니메이션) */}
              <Box
                sx={{
                  animation: `${floatAnimation} 3s ease-in-out infinite`,
                }}
              >
                <PlanCharacter />
              </Box>

              {/* 그림자 SVG (캐릭터 움직임에 따라 크기 변화) */}
              <Box
                sx={{
                  mt: 1,
                  animation: `${shadowAnimation} 3s ease-in-out infinite`,
                  transformOrigin: 'center center',
                }}
              >
                <PlanShadow />
              </Box>
            </Box>
          </Container>
        </Box>
      </Layout>
    );
  }

  // 메인 DAY 카드 화면 렌더링
  return (
    <Layout>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
          paddingTop: '10px',
        }}
      >
        {/* 고정된 네비게이션 헤더 */}
        <Box
          sx={{
            position: 'fixed',
            top: '10px',
            left: 0,
            right: 0,
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'white',
            zIndex: 1000,
          }}
        >
          <Link 
            to={`/plan/plan-detail?theme=${encodeURIComponent(themeParam)}`} 
            style={{ color: 'black' }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: 24 }} />
          </Link>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {categoryDisplayName}
          </Typography>
          <NotificationsNoneIcon sx={{ fontSize: 24 }} />
        </Box>

        <Container
          maxWidth="sm"
          sx={{
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 12, // Increased to accommodate fixed header
            pb: 1,
          }}
        >
          <SmallCharacter
            style={{
              position: 'absolute',
              top: '15%',
              left: '35%',
              transform: 'translateX(-50%)',
              width: '100px',
              height: 'auto',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              top: '15%',
              left: '50%',
              backgroundColor: '#1F80E0',
              color: '#fff',
              borderRadius: '10px',
              p: 1.5,
              fontSize: '0.9rem',
              boxShadow: '0 2px 6px rgba(0,0,0,0.15)',
              width: '120px',
              textAlign: 'center',
              '&:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '-10px',
                width: 0,
                height: 0,
                borderRight: '10px solid #1F80E0',
                borderTop: '6px solid transparent',
                borderBottom: '6px solid transparent',
              },
            }}
          >
            안녕?<br />{difficultyParam} 시작할게요!
          </Box>

          {/* DAY 카드들: 2열 레이아웃 */}
          <Grid container spacing={2.5} justifyContent="center" sx={{ pt: 20, width:'96%' }}>
            {days.map((day) => (
              <Grid item xs={6} key={day} display="flex" justifyContent="center">
                <Box
                  onClick={() => handleDayClick(day)}
                  sx={{
                    width: '100%',
                    aspectRatio: '1',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '20px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                    },
                  }}
                >
                  <Typography variant="body2" color="textPrimary" fontWeight="bold">
                    {day}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default Day;