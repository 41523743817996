import React from 'react';
import BottomNav from './BottomNav';
import { Box } from '@mui/material';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh'}}>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          overflow:'hidden'
        }}
      >
        {children}
      </Box>
      <BottomNav />
    </Box>
  );
};

export default Layout;
