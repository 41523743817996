import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkUserExists, checkUserExistsBySub } from '../../AuthService/cognitoAdmin';
import { signUp, signIn, getCurrentUser } from '../../AuthService/AuthService';
import { AuthContext } from './AuthContext';

const AppleCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation(); // URL 정보를 가져오기 위해 추가
  const { setUser, setSession } = useContext(AuthContext);
  const appleDefaultPassword = "AppleSignInPassword1!"; // Apple 전용 기본 비밀번호

  // 중복 실행 방지
  const effectRan = useRef(false);

  // 사용자 처리 함수 (회원가입/로그인)
  const processUser = useCallback(
    async (email: string, sub: string, name: string) => {
      try {
        // sub에 애플 접두어 추가 (Lambda 트리거에서 식별용)
        const appleSub = `apple_${sub}`;
        
        // sub로 이미 가입된 사용자가 있는지 확인 (가입된 경우 해당 이메일 반환)
        const subResult = await checkUserExistsBySub(appleSub);
        // 동일한 이메일로 가입된 사용자가 있는지 확인
        console.log(email)
        const existsByEmail = await checkUserExists(email);
        console.log(email, existsByEmail, subResult);

        if (subResult !== false) {
          // 이미 sub로 가입된 사용자가 있다면, 해당 이메일로 자동 로그인 시도
          try {
            const signInResult = await signIn(subResult, appleDefaultPassword);
            const currentUser = getCurrentUser();
            setUser(currentUser);
            setSession(signInResult);
            navigate('/');
          } catch (loginError) {
            console.error('자동 로그인 실패:', loginError);
            alert('자동 로그인이 실패했습니다. 기존 가입한 경로로 로그인해주세요.');
            navigate('/signin');
          }
        } else {
          if (existsByEmail) {
            // 동일 이메일로 가입된 사용자가 이미 있다면 에러 처리
            alert('이미 동일한 이메일로 가입된 회원이 있습니다.');
            navigate('/signin');
          } else {
            try {
              // 신규 회원 가입 - Lambda 트리거에 의해 자동 확인됨
              await signUp(email, appleDefaultPassword, name, '', '', appleSub);
              console.log('신규 회원가입 처리 완료 (apple 접두어 추가됨)');
              
              try {
                // 회원가입 완료 후 바로 로그인 시도
                console.log('자동 로그인 시도');
                const signInResult = await signIn(email, appleDefaultPassword);
                const currentUser = getCurrentUser();
                setUser(currentUser);
                setSession(signInResult);
                
                // 로그인 성공 시 홈 화면으로 이동
                navigate('/');
              } catch (loginError) {
                console.error('자동 로그인 실패:', loginError);
                // 자동 로그인 실패 시 확인 페이지로 이동
                alert('자동 로그인에 실패했습니다. 이메일 확인이 필요할 수 있습니다.');
                navigate('/confirm-signup', { state: { email } });
              }
            } catch (signupError) {
              console.error('회원가입 실패:', signupError);
              alert(`회원가입 처리 중 오류가 발생했습니다: ${signupError}`);
              navigate('/signin');
            }
          }
        }
      } catch (error) {
        console.error('사용자 처리 중 오류 발생:', error);
        navigate('/signin');
      }
    },
    [navigate, setUser, setSession, appleDefaultPassword]
  );

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const handleAppleLogin = async () => {
      // 수정된 부분: 이제 Lambda 함수에서 리디렉션된 URL의 쿼리 파라미터에서 토큰을 추출
      const urlParams = new URLSearchParams(location.search);
      const idToken = urlParams.get('id_token');
      
      // 이전 방식(hash 사용)도 폴백으로 유지
      let hashToken = null;
      if (!idToken && window.location.hash) {
        const hash = window.location.hash;
        const params = new URLSearchParams(hash.slice(1));
        hashToken = params.get('id_token');
      }
      
      // 최종적으로 사용할 토큰
      const finalToken = idToken || hashToken;

      if (!finalToken) {
        console.error('ID 토큰을 찾을 수 없습니다. URL:', window.location.href);
        alert('Apple ID 토큰을 찾을 수 없습니다.');
        navigate('/signin');
        return;
      }

      // id_token 디코딩하여 사용자 정보 추출
      let userInfo;
      try {
        const tokenParts = finalToken.split('.');
        if (tokenParts.length < 2) {
          throw new Error('유효하지 않은 ID 토큰 형식입니다.');
        }
        const payload = tokenParts[1];
        // Base64Url -> Base64 변환
        const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
        const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');
        const jsonPayload = decodeURIComponent(
          atob(padded)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        );
        userInfo = JSON.parse(jsonPayload);
      } catch (error) {
        console.error('ID 토큰 디코딩 실패:', error);
        alert('ID 토큰을 디코딩하는데 실패했습니다.');
        navigate('/signin');
        return;
      }

      // Apple id_token에서 사용자 정보 추출
      console.log('사용자 정보:', userInfo);
      const sub = userInfo.sub;
      const payloadEmail = userInfo.email;
      const name = userInfo.name || '';

      if (!sub) {
        alert('Apple 계정에 사용자 식별자(sub) 정보가 없습니다.');
        navigate('/signin');
        return;
      }

      // 이메일 정보가 없으면, sub로 등록된 사용자가 있는지 확인
      if (!payloadEmail) {
        const appleSub = `apple_${sub}`;
        const subResult = await checkUserExistsBySub(appleSub);
        if (subResult !== false) {
          // 이미 가입된 사용자의 이메일로 자동 로그인
          await processUser(subResult, sub, name);
          return;
        } else {
          const fallbackEmail = `${sub}@example.com`;
          console.log(fallbackEmail)
          await processUser(fallbackEmail, sub, name);
        }
      }

      // 이메일 정보가 존재하면 회원가입/로그인 처리
      await processUser(payloadEmail, sub, name);
    };

    handleAppleLogin();
  }, [navigate, processUser, location.search]); // location.search 의존성 추가

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div>Apple 로그인 처리 중...</div>
    </div>
  );
};

export default AppleCallback;