import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Switch,
  Slider,
  Button,
  TextField,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUserAttributes, updateUserAttributes } from '../../AuthService/AuthService';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import notificationService from '../../services/NotificationService';

const AppSetting = () => {
  const navigate = useNavigate();

  const [themeMode, setThemeMode] = useState('light');
  const [pushNotifications, setPushNotifications] = useState(false);
  const [aiNotifications, setAiNotifications] = useState(false);
  const [eventNotifications, setEventNotifications] = useState(false);
  const [doNotDisturb, setDoNotDisturb] = useState(false);
  const [dndStartTime, setDndStartTime] = useState('00:00');
  const [dndEndTime, setDndEndTime] = useState('23:59');
  const [voiceVolume, setVoiceVolume] = useState(30);
  const [effectVolume, setEffectVolume] = useState(50);

  const [isLoading, setIsLoading] = useState(true);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const attributes = await getUserAttributes();
        
        // 단일 appSetting 속성에서 모든 설정 로드
        const appSettingsStr = attributes['custom:appSetting'];
        
        if (appSettingsStr) {
          const appSettings = JSON.parse(appSettingsStr);
          
          // 파싱된 객체에서 모든 상태 설정
          setThemeMode(appSettings.themeMode || 'light');
          setPushNotifications(appSettings.pushNotifications === true);
          setAiNotifications(appSettings.aiNotifications === true);
          setEventNotifications(appSettings.eventNotifications === true);
          setDoNotDisturb(appSettings.doNotDisturb === true);
          setDndStartTime(appSettings.dndStartTime || '00:00');
          setDndEndTime(appSettings.dndEndTime || '23:59');
          setVoiceVolume(parseInt(appSettings.voiceVolume || 30, 10));
          setEffectVolume(parseInt(appSettings.effectVolume || 50, 10));
        } else {
          // 설정이 없으면 기본값 사용
          setThemeMode('light');
          setPushNotifications(false);
          setAiNotifications(false);
          setEventNotifications(false);
          setDoNotDisturb(false);
          setDndStartTime('00:00');
          setDndEndTime('23:59');
          setVoiceVolume(30);
          setEffectVolume(50);
        }
      } catch (error) {
        console.error('설정 불러오기 오류:', error);
        alert('설정을 불러오는 데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
    
    // Initialize the notification service
    const initNotifications = async () => {
      await notificationService.initialize();
    };
    
    initNotifications();
  }, []);

  // Update notification service when settings change
  useEffect(() => {
    if (!isLoading) {
      notificationService.updateSettings(
        pushNotifications,
        doNotDisturb,
        dndStartTime,
        dndEndTime
      );
    }
  }, [pushNotifications, doNotDisturb, dndStartTime, dndEndTime, isLoading]);

  const handlePushToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setPushNotifications(newValue);
    
    // If turning on notifications, request permission
    if (newValue) {
      notificationService.initialize();
    }
  };

  const handleSaveSettings = async () => {
    try {
      // 모든 설정을 포함하는 단일 객체 생성
      const appSettings = {
        themeMode,
        pushNotifications,
        aiNotifications,
        eventNotifications,
        doNotDisturb,
        dndStartTime,
        dndEndTime,
        voiceVolume,
        effectVolume,
      };

      // 단일 속성으로 저장
      const attributesToUpdate = {
        'custom:appSetting': JSON.stringify(appSettings),
      };

      await updateUserAttributes(attributesToUpdate);
      
      // Update notification service with the latest settings
      notificationService.updateSettings(
        pushNotifications,
        doNotDisturb,
        dndStartTime,
        dndEndTime
      );
      
      alert('설정이 성공적으로 저장되었습니다.');
    } catch (error) {
      console.error('설정 저장 오류:', error);
      alert('설정 저장에 실패했습니다. 다시 시도해주세요.');
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">설정을 불러오는 중입니다...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: '10px',
      }}
    >
      {/* 상단 AppBar */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            기능 설정
          </Typography>
        </Toolbar>
      </AppBar>

      {/* 설정 영역 박스 */}
      <Box
        sx={{
          mt: '20px',
          backgroundColor: 'white',
          p: '20px',
          borderRadius: '10px',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
          width: '90%',
          maxWidth: '500px',
        }}
      >
        {/* 알림 설정 */}
        <Box sx={{ mb: '30px' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            알림
          </Typography>

          {/* 푸쉬 알림 */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '15px',
            }}
          >
            <Typography>푸쉬 알림</Typography>
            <Switch
              checked={pushNotifications}
              onChange={handlePushToggle}
              sx={{
                '.MuiSwitch-thumb': { backgroundColor: '#D8A45F' },
              }}
            />
          </Box>

          {/* 이벤트 혜택 알림 */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '15px',
            }}
          >
            <Typography>이벤트 혜택 알림</Typography>
            <Switch
              checked={eventNotifications}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEventNotifications(e.target.checked)}
              sx={{
                '.MuiSwitch-thumb': { backgroundColor: '#D8A45F' },
              }}
            />
          </Box>

          {/* 방해 금지 시간 */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: doNotDisturb ? '10px' : '15px',
            }}
          >
            <Typography>방해 금지 시간</Typography>
            <Switch
              checked={doNotDisturb}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDoNotDisturb(e.target.checked)}
              sx={{
                '.MuiSwitch-thumb': { backgroundColor: '#D8A45F' },
              }}
            />
          </Box>

          {/* 방해 금지 시간 설정 (토글 시 표시) */}
          {doNotDisturb && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                mb: '15px',
              }}
            >
              {/* 시작 시간 */}
              <TextField
                type="time"
                variant="outlined"
                value={dndStartTime}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDndStartTime(e.target.value)}
                sx={{
                  width: '60%',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    backgroundColor: '#F2E6D2',
                    '& fieldset': {
                      border: 'none',
                    },
                    height: '40px',
                    '& input': {
                      padding: '8px 12px',
                      textAlign: 'center',
                    },
                  },
                }}
              />
              <Typography sx={{ fontSize: '30px' }}>-</Typography>
              {/* 종료 시간 */}
              <TextField
                type="time"
                variant="outlined"
                value={dndEndTime}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDndEndTime(e.target.value)}
                sx={{
                  width: '60%',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    backgroundColor: '#F2E6D2',
                    '& fieldset': {
                      border: 'none',
                    },
                    height: '40px',
                    '& input': {
                      padding: '8px 12px',
                      textAlign: 'center',
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>

        {/* 음성 및 효과음 */}
        <Box sx={{ mb: '30px' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
            음성 및 효과음
          </Typography>

          {/* 음성 */}
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            <Typography variant="body2" sx={{ mr: 2.5 }}>
              음성
            </Typography>
            <VolumeUpIcon />
            <Slider
              value={voiceVolume}
              onChange={(_event: Event, newValue: number | number[]) => setVoiceVolume(newValue as number)}
              aria-labelledby="voice-slider"
              sx={{
                width: '70%',
                color: '#D8A45F',
                flexGrow: 1,
                '& .MuiSlider-thumb': {
                  width: 16,
                  height: 16,
                },
              }}
              min={0}
              max={100}
            />
          </Box>

          {/* 효과음 */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              효과음
            </Typography>
            <VolumeUpIcon  />
            <Slider
              value={effectVolume}
              onChange={(_event: Event, newValue: number | number[]) => setEffectVolume(newValue as number)}
              aria-labelledby="effect-slider"
              sx={{
                width: '70%',
                color: '#D8A45F',
                flexGrow: 1,
                '& .MuiSlider-thumb': {
                  width: 16,
                  height: 16,
                },
              }}
              min={0}
              max={100}
            />
          </Box>
        </Box>

        {/* 저장 버튼 */}
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={handleSaveSettings}
            sx={{
              backgroundColor: '#D8A45F',
              '&:hover': { backgroundColor: '#b5884a' },
              width: '100px',
              borderRadius: '20px',
            }}
          >
            저장
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AppSetting;