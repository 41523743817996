import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setProfileData } from '../../store/profileSlice';
import { keyframes } from '@mui/system';
import PlanCharacter from '../../svg/PlanCharacter';
import PlanShadow from '../../svg/PlanShadow';
import { 
  Box, 
  Container, 
  Typography, 
  IconButton, 
  Stack, 
  LinearProgress,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  CircularProgress,
  Badge  // Added Badge component
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SmallCharacter from '../../svg/SmallCharacter';
import { getUserAttributes } from '../../AuthService/AuthService';
import notificationService from '../../services/NotificationService';

// 캐릭터를 위아래로 둥실둥실 움직이는 애니메이션
const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

// 그림자가 캐릭터 움직임에 따라 작아졌다 커졌다 하는 애니메이션
const shadowAnimation = keyframes`
  0% { transform: scale(1.2); }
  50% { transform: scale(0.8); }
  100% { transform: scale(1.2); }
`;

// 테마에 따른 카테고리 이름 표시 설정
const getCategoryDisplayName = (theme: string, categoryId: string): string => {
  const themeCategories: Record<string, Record<string, string>> = {
    '일상': {
      '친구': '일상(친구)',
      '썸': '일상(썸)'
    },
    '비즈니스': {
      '출근': '비즈니스(출근)',
      '미팅': '비즈니스(미팅)'
    },
    '여행': {
      '쇼핑': '여행(쇼핑)',
      '관광': '여행(관광)'
    },
    '문화': {
      '콘서트': '문화(콘서트)',
      '영화관': '문화(영화관)'
    },
    '드라마': {
      '로맨스': '드라마(로맨스)',
      '스릴러': '드라마(스릴러)'
    }
  };

  return themeCategories[theme]?.[categoryId] || categoryId;
};


interface PlanData {
  theme: string;
  category: string;
  completedDays: string[];
  planNumber: number;
  difficulty?: string; // 난이도 추가
}

// 7일을 기준으로 하는 상수
const TOTAL_DAYS = 7;

const HomePlan: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPlanIndex, setCurrentPlanIndex] = useState<number>(0);
    const [plans, setPlans] = useState<PlanData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [transitionActive, setTransitionActive] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState<boolean>(false); // Added state for unread notifications
    console.log(selectedDay)
    
    // Get profile data from Redux store
    const profileData = useSelector((state: RootState) => state.profile);
    const [nickname, setNickname] = useState<string>("");

    // Check for unread notifications when component loads
    useEffect(() => {
        const checkUnreadNotifications = async () => {
            try {
                // Initialize notification service if not already
                await notificationService.initialize();
                
                // Check for unread notifications
                const hasUnread = await notificationService.hasUnreadNotifications();
                setHasUnreadNotifications(hasUnread);
            } catch (error) {
                console.error('Failed to check unread notifications:', error);
            }
        };
        
        checkUnreadNotifications();
    }, []);

    // Handler for notification icon click
    const handleNotificationClick = async () => {
        try {
            // Mark all notifications as read
            await notificationService.markAllAsRead();
            setHasUnreadNotifications(false);
            // Here you could navigate to a notifications page if you have one
        } catch (error) {
            console.error('Failed to mark notifications as read:', error);
        }
    };

    // 사용자의 프로필 및 플랜 정보 불러오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setError(null);
                
                const attributes = await getUserAttributes();
                const plan1 = attributes['custom:plan1'] || '';
                const plan2 = attributes['custom:plan2'] || '';
                
                // 닉네임 정보 저장
                const userNickname = attributes['custom:nickname'] || '';
                setNickname(userNickname);
                
                // Redux 스토어에 프로필 정보 저장 (필요한 경우 다른 컴포넌트에서 사용)
                dispatch(
                    setProfileData({
                        name: attributes['custom:name'] || '',
                        nickname: attributes['custom:nickname'] || '',
                        introduction: attributes['custom:introduction'] || '',
                        image: attributes['custom:picture'] || '/path/to/profile.jpg',
                        dream1: attributes['custom:dream1'] || '',
                        dream2: attributes['custom:dream2'] || '',
                        dream3: attributes['custom:dream3'] || '',
                        dream4: attributes['custom:dream4'] || '',
                        dream5: attributes['custom:dream5'] || '',
                        dream6: attributes['custom:dream6'] || '',
                        dream7: attributes['custom:dream7'] || '',
                        dreamtext: attributes['custom:dreamtext'] || '',
                    })
                );
                
                const parsedPlans: PlanData[] = [];
                
                        // plan1 파싱
                if (plan1) {
                    const parts = plan1.split('|');
                    if (parts.length >= 2) {
                        const theme = parts[0];
                        const category = parts[1];
                        const completedDays = parts.length > 2 ? parts[2].split(',') : [];
                        
                        // 난이도 정보 (기본값: '초급')
                        const difficulty = parts.length > 3 ? parts[3] : '초급';
                        
                        parsedPlans.push({
                            theme,
                            category,
                            completedDays,
                            planNumber: 1,
                            difficulty
                        });
                    }
                }
                
                // plan2 파싱
                if (plan2) {
                    const parts = plan2.split('|');
                    if (parts.length >= 2) {
                        const theme = parts[0];
                        const category = parts[1];
                        const completedDays = parts.length > 2 ? parts[2].split(',') : [];
                        
                        // 난이도 정보 (기본값: '초급')
                        const difficulty = parts.length > 3 ? parts[3] : '초급';
                        
                        parsedPlans.push({
                            theme,
                            category,
                            completedDays,
                            planNumber: 2,
                            difficulty
                        });
                    }
                }
                
                setPlans(parsedPlans);
            } catch (error) {
                console.error('플랜 정보 불러오기 오류:', error);
                setError('플랜 정보를 불러오는 중 오류가 발생했습니다.');
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchData();
    }, [dispatch]);
    
    // 현재 플랜 변경 핸들러
    const handlePlanSwitch = () => {
        if (plans.length > 1) {
            setCurrentPlanIndex((prev) => (prev + 1) % plans.length);
        }
    };
    
    // 현재 선택된 플랜
    const currentPlan = plans[currentPlanIndex] || null;
    
    // 진행률 계산
    const calculateProgress = (completedDays: string[]): number => {
        if (!completedDays || completedDays.length === 0) return 0;
        return (completedDays.length / TOTAL_DAYS) * 100;
    };
    
    // Day 클릭 핸들러
    const handleDayClick = (day: number) => {
        if (!currentPlan) return;
        
        setSelectedDay(day);
        setTransitionActive(true);
        
        const { theme, category, difficulty = '초급' } = currentPlan;
        
        // 3초 후 리스닝 페이지로 이동
        setTimeout(() => {
            navigate(`/plan/listening/${day}?theme=${encodeURIComponent(theme)}&category=${encodeURIComponent(category)}&difficulty=${encodeURIComponent(difficulty)}`);
        }, 3000);
    };
    
    // Day 아이템 생성
    const generateDayItems = () => {
        if (!currentPlan) return Array(TOTAL_DAYS).fill(0).map((_, i) => ({ day: i + 1, text: '', done: false }));
        
        return Array(TOTAL_DAYS).fill(0).map((_, i) => {
            const day = i + 1;
            const isDone = currentPlan.completedDays.includes(day.toString());
            
            return {
                day,
                text: '',
                done: isDone
            };
        });
    };
    
    const dayItems = generateDayItems();
    const progress = currentPlan ? calculateProgress(currentPlan.completedDays) : 0;
    
    // 남은 일수 계산
    const getRemainingDays = (): number => {
        if (!currentPlan || !currentPlan.completedDays.length) return TOTAL_DAYS;
        return TOTAL_DAYS - currentPlan.completedDays.length;
    };

    // 플랜 제목 생성
    const getPlanTitle = (): string => {
        if (!currentPlan) return "Loading plan...";
        
        const categoryDisplay = getCategoryDisplayName(currentPlan.theme, currentPlan.category);
        const userDisplayName = nickname || profileData.nickname || "User";
        return `${userDisplayName}'s ${categoryDisplay} Korean plan`;
    };

    if (isLoading) {
        return (
            <Layout>
                <Box
                    sx={{
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Box>
            </Layout>
        );
    }

    if (error || plans.length === 0) {
        return (
            <Layout>
                <Box
                    sx={{
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        p: 2
                    }}
                >
                    <Typography variant="h6" align="center" gutterBottom>
                        {error || '진행 중인 플랜이 없습니다.'}
                    </Typography>
                    <Typography variant="body2" align="center">
                        플랜 페이지에서 새 플랜을 시작하세요.
                    </Typography>
                </Box>
            </Layout>
        );
    }
    
    // 전환 화면 (Day 컴포넌트의 transition과 동일하게 구현)
    if (transitionActive) {
        return (
            <Layout>
                <Box
                    sx={{
                        width: '100%',
                        minHeight: '100vh',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                        position: 'relative',
                        paddingTop: '10px',
                    }}
                >
                    <Container
                        maxWidth="sm"
                        sx={{
                            height: 'calc(100vh - 100px)',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            pt: 8,
                        }}
                    >
                        {/* 전환 화면 콘텐츠 */}
                        <Box
                            sx={{
                                mt: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                position: 'relative',
                            }}
                        >
                            {/* "LET'S GET IT !" 텍스트 */}
                            <Typography
                                variant="h3"
                                sx={{
                                    color: '#A24ABB',
                                    fontWeight: 'bold',
                                    mb: 8,
                                    textAlign: 'center',
                                }}
                            >
                                LET'S GET IT !
                            </Typography>

                            {/* 캐릭터 SVG (둥실둥실 애니메이션) */}
                            <Box
                                sx={{
                                    animation: `${floatAnimation} 3s ease-in-out infinite`,
                                }}
                            >
                                <PlanCharacter />
                            </Box>

                            {/* 그림자 SVG (캐릭터 움직임에 따라 크기 변화) */}
                            <Box
                                sx={{
                                    mt: 1,
                                    animation: `${shadowAnimation} 3s ease-in-out infinite`,
                                    transformOrigin: 'center center',
                                }}
                            >
                                <PlanShadow />
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </Layout>
        );
    }

    return (
        <Layout>
            <Box
                sx={{
                    width: '100%',
                    height: '100vh', 
                    background: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        height: 'calc(100vh - 100px)',
                        overflowY: 'auto',
                        position: 'relative', 
                    }}
                >
                    <Box sx={{ position: 'absolute', top: '16px', left: '16px', zIndex: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Home</Typography>
                        <Box 
                            sx={{ 
                                mt: 1, 
                                backgroundColor: 'rgba(0,0,0,0.05)', 
                                borderRadius: '20px', 
                                padding: '4px 12px',
                            }}
                        >
                            <Typography variant="body2">
                                진행중인 플랜 {currentPlan?.planNumber || ''} {plans.length > 1 ? `/ ${plans.length}` : ''}
                            </Typography>
                        </Box>
                    </Box>
                    
                    <Box sx={{ position: 'absolute', top: '16px', right: '16px', zIndex: 3 }}>
                        <IconButton onClick={handleNotificationClick}>
                            <Badge 
                                color="error" 
                                variant="dot" 
                                invisible={!hasUnreadNotifications}
                            >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Box>

                    <Container
                        maxWidth="sm"
                        sx={{
                            marginTop: '80px',
                            overflow: 'hidden',
                            position: 'relative',
                            pb: 2
                        }}
                    >
                        {/* 플랜 카드 영역 */}
                        <Card sx={{ 
                            mt: 2, 
                            p: 2, 
                            borderRadius: '16px', 
                            backgroundColor: '#FFFCB8', 
                            boxShadow: '0px 2px 8px rgba(0,0,0,0.1)',
                            position: 'relative'
                        }}>
                            <CardContent sx={{ p: 0, pb: '16px' }}>
                                {/* 상단에 제목 */}
                                <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 'bold', mb: 1 }}>
                                    {getPlanTitle()}
                                </Typography>

                                {/* 제목 아래 영역: 왼쪽 아래에 이미지, 오른쪽 아래에 목표 */}
                                <Box sx={{ display: 'flex', position: 'relative', mb: 2 }}>
                                    {/* 왼쪽 하단 이미지 - 클릭 시 플랜 전환 */}
                                    <Box 
                                        sx={{ 
                                            position: 'absolute', 
                                            top: 0, 
                                            left: -5,
                                            cursor: plans.length > 1 ? 'pointer' : 'default',
                                            transition: 'transform 0.2s',
                                            '&:hover': {
                                                transform: plans.length > 1 ? 'scale(1.05)' : 'none'
                                            }
                                        }}
                                        onClick={handlePlanSwitch}
                                    >
                                        <SmallCharacter 
                                            style={{
                                                width: '90%',
                                                height: 'auto',
                                            }}
                                        />
                                    </Box>

                                    {/* 오른쪽 하단 목표 텍스트 */}
                                    <Box sx={{ 
                                        position: 'absolute', 
                                        right: 0, 
                                        textAlign: 'right', 
                                        top: '50%', 
                                        transform: 'translateY(-50%)' 
                                    }}>
                                        <Typography variant="body2" sx={{ color: '#666' }}>
                                            목표: 한국어로 {getCategoryDisplayName(currentPlan.theme, currentPlan.category)} 할 수 있어요!
                                        </Typography>
                                    </Box>

                                    {/* 이 영역은 높이를 확보하기 위해 최소 높이를 지정하거나 상단 패딩 등을 줄 수 있음 */}
                                    <Box sx={{ height: '60px', width: '100%' }}></Box>
                                </Box>

                                {/* 진행률 관련 섹션 */}
                                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
                                    <Typography variant="body2" sx={{ color: '#666' }}>현재진행률</Typography>
                                    <Typography variant="body2" sx={{ color: '#666' }}>
                                        D-{getRemainingDays()}
                                    </Typography>
                                </Stack>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={progress} 
                                    sx={{ 
                                        borderRadius: '4px', 
                                        height: '8px',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#F6E123'
                                        }
                                    }} 
                                />
                            </CardContent>
                        </Card>

                        {/* Day 리스트 */}
                        <List sx={{ mt: 2 }}>
                            {dayItems.map((item, index) => (
                                <React.Fragment key={item.day}>
                                    <ListItem 
                                        sx={{ 
                                            pl:0,
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0,0,0,0.02)'
                                            },
                                            backgroundColor:'#FFFCB8',
                                            mb:1,
                                        }}
                                        onClick={() => handleDayClick(item.day)}
                                        secondaryAction={
                                            <IconButton edge="end" onClick={(e) => {
                                                e.stopPropagation();
                                                handleDayClick(item.day);
                                            }}>
                                                <ArrowForwardIosIcon sx={{ fontSize: '16px' }}/>
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Checkbox 
                                                disabled 
                                                checked={item.done}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={`Day ${item.day}`} 
                                            secondary={item.text} 
                                            primaryTypographyProps={{ fontWeight: 'bold', fontSize: '1rem' }}
                                            secondaryTypographyProps={{ fontSize: '0.9rem', color: '#666' }}
                                        />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </Container>
                </Container>
            </Box>
        </Layout>
    );
};

export default HomePlan;